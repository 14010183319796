<template>
  <div class="">
    <!-- 列表 -->

    <el-card
      class="box-card listbox"
      style="margin-top: 15px"
      v-if="userList !=null && userList.length == 0"
    >
      <!-- 无数据 -->
      <div class="nodata">
        <img src="../../assets/images/person/p-nodata.png" />
        <div class="font16">当前无数据</div>
      </div>
    </el-card>
    <div class="box-card listbox" style="margin-top: 15px">
      <div class="">
        <el-card
          class="li-main"
          v-for="(itemes, index3) in userList"
          :key="index3"
        >
          <div class="list-content" @click="todetail(itemes)">
            <div class="person-info">
              <div class="p-picurl">
                <img
                  :src="'https://freemen.work/user/file/view/' + itemes.avatar"
                />
                <span
                  class="bofang font20"
                  v-if="itemes.resUrl"
                  @click.stop="videoshow(itemes)"
                >
                  <!-- <i class="el-icon-video-play"></i> -->
                  <img src="../../assets/images/common/bofangicon.png" />
                </span>
              </div>
              <div class="p-news">
                <div class="pn-name">
                  <div class="namemain">
                    <span class="n-name font22">{{ itemes.displayName }}</span>
                    <img
                      src="../../assets/images/person/boy.png"
                      v-if="itemes.sex == 1"
                    />
                    <img src="../../assets/images/person/girl.png" v-else />
                    <span class="font14">Free</span>
                  </div>
                  <div class="score font14">
                    <span>{{ itemes.score }}</span>
                  </div>
                </div>
                <div class="beizhu font14">
                  <!-- <div class="">{{itemes.expYear}}年 | {{itemes.educationList.length ? itemes.educationList[itemes.educationList.length-1].educationType.itemText : '/'}} | {{new Date().getYear() - new Date(itemes.birthday).getYear()}}岁  -->
                  <div class="">
                    {{ itemes.expYear }}年 |
                    {{
                      new Date().getYear() -
                      new Date(itemes.birthday).getYear() -
                      (new Date().getMonth() >
                      new Date(itemes.birthday).getMonth()
                        ? 0
                        : 1)
                    }}岁 | {{ itemes.jobLevelNames }}
                  </div>
                  <div class="">
                    期望工作时间：{{
                      itemes.startDate ? itemes.startDate.split(" ")[0] : "/"
                    }}
                    —
                    {{ itemes.endDate ? itemes.endDate.split(" ")[0] : "/" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="transitionbox" v-if="itemes.userWorkExpList.length > 0">
              <div class="tr-left">
                <!-- 3个以内 -->
                <template v-if="itemes.userWorkExpList.length < 4">
                  <div
                      class="t-one font14"
                      v-for="(item, index) in itemes.userWorkExpList"
                      :key="index"
                  >
                    <img src="../../assets/images/person/gangwei.png" />
                    <span @click.stop="getMoreWork(itemes)">
                    {{ item.companyName }} ·
                    {{ item.jobName }}
                  </span>
                  </div>
                </template>
                <!-- 大于3 -->
                <template v-if="itemes.userWorkExpList.length > 3">
                  <div class="t-one font14">
                    <img src="../../assets/images/person/gangwei.png" />
                    <span @click.stop="getMoreWork(itemes)">
                    {{ itemes.userWorkExpList[0].companyName }} ·
                    {{ itemes.userWorkExpList[0].jobName }}
                  </span>
                    <i
                        :class="
                      itemes.moreShow
                        ? 'el-icon-arrow-up'
                        : 'el-icon-arrow-down'
                    "
                        @click.stop="getMoreWork(itemes)"
                    ></i>
                  </div>
                </template>
                <!-- 大于3 -->
                <el-collapse-transition v-if="itemes.userWorkExpList.length > 3">
                  <div v-show="itemes.moreShow">
                    <div
                        class="t-one font14"
                        v-show="index > 0"
                        v-for="(item, index) in itemes.userWorkExpList"
                        :key="index"
                    >
                      <img src="../../assets/images/person/gangwei.png" />
                      <span>{{ item.companyName }} · {{ item.jobName }}</span>
                    </div>
                  </div>
                </el-collapse-transition>
              </div>
              <div class="tr-right font14">
                收藏于 {{ itemes.companyJobName }} 岗位
              </div>
            </div>
            <div class="transitionbox" v-if="itemes.userWorkExpList.length === 0">
              <div class="tr-right font14">
                收藏于 {{ itemes.companyJobName }} 岗位
              </div>
            </div>
            <div class="label-info">
              <div class="li-left font14">
                <span
                  v-show="index < 5"
                  v-for="(item, index) in itemes.skillIds"
                  :key="index"
                  >{{ item.itemText }}</span
                >
              </div>
              <div class="li-right">
                <!-- <el-button class="gt" type="primary" @click="cardDialogVisible=true">沟通</el-button> -->
                <el-button
                  class="qx"
                  type="primary"
                  @click.stop="collect(itemes)"
                  >取消收藏</el-button
                >
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div class="nextpage" @click="getMoreList" v-if="moreBtn">
        点击加载更多
      </div>
    </div>
    <!-- 分页 -->
    <!-- <div class="paginate" style="position: relative;margin-top: 100px;">
			<el-pagination
				@current-change="handleCurrentChange"
				:current-page="pageInfo.pageNum"
				:page-size="pageInfo.pageSize"
				layout="total, prev, pager, next, jumper"
				:total="pageInfo.count"
			></el-pagination>
		</div> -->

    <!-- 沟通消耗聊天卡弹窗 -->
    <el-dialog
      :visible.sync="cardDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/yongcard.png" />
        </div>
        <div class="ub-text font22">发起聊天将消耗一次聊天卡，确认继续？</div>
        <div class="btnbox">
          <el-button
            class="qx"
            type="primary"
            @click="cardDialogVisible = false"
            >取消</el-button
          >
          <el-button
            class="qr"
            type="primary"
            @click="cardDialogVisible = false"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 聊天卡额度不足弹窗 -->
    <el-dialog
      :visible.sync="nocardDialogVisible"
      width="600px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="updown_box">
        <div class="ub-img">
          <img src="../../assets/images/person/qianbao.png" />
        </div>
        <div class="ub-text font22">当前聊天卡额度不足，是否购买？</div>
        <div class="btnbox">
          <el-button
            class="qx"
            type="primary"
            @click="nocardDialogVisible = false"
            >取消</el-button
          >
          <el-button class="qr" type="primary" @click="buyCard"
            >去购买</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
      },
      moreBtn: false,
      userList: null,
      cardDialogVisible: false, //消耗聊天卡弹窗
      nocardDialogVisible: false, //聊天卡额度不足弹窗
    };
  },
  activated() {
    this.getList();
  },

  computed: {},
  methods: {
    buyCard() {
      this.nocardDialogVisible = false;
      this.dialogVisible = false;
      window.location.href = window.location.origin + "/#/propsmall";
    },
    getList() {
      this.$api.getCollectList("get", this.pageInfo).then((res) => {
        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
        // this.userList = this.userList.concat(res.data);

        if (this.pageInfo.pageNo == 1) {
          this.userList = res.data;
        } else {
          this.userList = this.userList.concat(res.data);
        }
        this.userList.map((item) => {
          item.moreShow = false;
        });
      });
    },
    getMoreList() {
      //下一页
      this.pageInfo.pageNo++;
      this.getList();
    },
    getMoreWork(data) {
      //展示所有工作经历
      data.moreShow = !data.moreShow;
      this.$forceUpdate();
    },
    collect(data) {
      let config = {
        userId: data.userId,
        companyJobId: data.companyJobId,
        userJobId: data.userJobId,
        id: data.id,
      };
      this.$api.collect("post", config).then(() => {
        this.pageInfo.pageNo = 1;
        this.userList = [];
        this.getList();
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },
    fetchData(page = 1) {
      // this.table_loading = true;
      this.pageInfo.pageNum = page;
      // this.formInline.pageNum = this.pageInfo.pageNum;
      this.getdata();
    },
    handleCurrentChange(val) {
      this.fetchData(val);
    },
    getdata() {
      this.userList = [
        {
          id: 1,
          position: "安卓开发工程师",
          name: "张珊",
          Flag: false,
          show3: false,
        },
        {
          id: 2,
          position: "前端开发工程师",
          name: "李四",
          Flag: false,
          show3: false,
        },
        // { id: 3, position: '后端开发工程师', name: '王五', Flag: false, show3:false },
        // { id: 4, position: '架构开发工程师', name: '孙六', Flag: false, show3:false }
      ];
      // this.userListId = [1, 2, 3, 4]; //初始化多选选择框
    },
    videoshow(data) {
      let config = {
        show: true,
        data: data,
      };
      this.$videoDetail.show(config);
    },
    todetail(data) {
      // if(data.isRead == 1){
      this.$resumeDetail.show({
        show: true,
        type: "shoucang",
        companyJobId: data.companyJobId,
        userJobId: data.userJobId,
        positionoptions: this.positionoptions,
      });
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.listbox {
  .nodata {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #111111;
    padding: 37px 0 20px 0;
    img {
      width: 256px;
      height: 215px;
      margin-bottom: 20px;
    }
  }
  .nextpage {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    cursor: pointer;
    margin-top: 15px;
  }
  .li-main:hover {
    transform: scale(1.02);
    background-color: rgba(0, 0, 0, 0.001);
  }
  .li-main {
    margin-bottom: 15px;
    .list-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      .person-info {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 20px;
        .p-picurl {
          margin-right: 20px;
          position: relative;
          img {
            width: 50px;
            height: 50px;
          }
          .bofang:hover {
            cursor: pointer;
          }
          .bofang {
            position: absolute;
            top: -3px;
            right: -6px;
            // border-radius: 50px;
            // color: #3F3F3F;
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
        .p-news {
          display: flex;
          flex: 1;
          flex-direction: column;
          line-height: 1.6;
          .pn-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .namemain {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .n-name {
                // filter: blur(2px);
              }
              img {
                width: 24px;
                height: 24px;
                margin: 0 18px 0 10px;
              }
              span:last-child {
                background-color: #e9f9ff;
                color: #00bcff;
                padding: 2px 10px;
              }
            }
            .score {
              background-color: #00bcff;
              border-radius: 8px 8px 8px 0;
              color: #ffffff;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .beizhu {
            color: #666666;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            div {
              margin-right: 48px;
            }
          }
        }
      }
      .transitionbox {
        .t-one:hover {
          cursor: pointer;
        }
        .tr-left {
          display: inline-block;
        }
        .tr-right {
          text-align: center;
          float: right;
          line-height: 40px;
          top: 50%;
          padding-top: 10px;
          color: #666666;
        }
        .t-one {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          color: #666666;
          margin-top: 20px;
          img {
            width: 22px;
            height: 20px;
          }
          span {
            margin: 0 30px 0 10px;
          }
        }
      }
      .label-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .li-left {
          span {
            background-color: #eeeeee;
            padding: 4px 8px;
            margin-right: 10px;
            color: #666666;
          }
        }
        .li-right {
          display: flex;
          align-items: center;
          /deep/ .el-button {
            border-radius: 0px;
            height: 40px;
            // margin-left: 30px;
            border: none;
          }
          .gt {
            background-color: #00bcff;
          }
          .qx {
            background-color: #bbbbbb;
            margin-left: 30px;
          }
        }
      }
    }
  }
}
.updown_box {
  line-height: 3;
  padding: 40px 0;
  .ub-img {
    text-align: center;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .ub-text {
    text-align: center;
    color: #111111;
  }
  .btnbox {
    text-align: center;
    /deep/ .el-button {
      border-radius: 0px;
      width: 200px;
      margin-top: 50px;
      background: #00bcff;
      border: none;
    }
    .qx {
      background-color: #ffffff;
      color: #00bcff;
      border: 1px solid #00bcff;
      margin-right: 10px;
    }
  }
}

.updown_box {
  padding: 20px;
  text-align: center;
}
.updown_box .ub-img img {
  width: 50px;
}
.ub-text {
  margin: 20px 0 50px 0;
}
.updown_box .qx {
  border: 1px solid #00bcff;
  background-color: #ffffff;
  color: #00bcff;
  width: 150px;
  margin-right: 20px;
}
.updown_box .qr {
  background-color: #00bcff;
  border: 1px solid #00bcff;
  width: 150px;
  margin-left: 20px;
}
</style>
